import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./Home";
import ComplainForm from "./ComplainForm";
import { ToastContainer } from "react-toastify";
import ComplainTable from "./ComplainTable";
import Login from "./Login";
import { UserAuthContextProvider } from "./context/UserAuthContext";
import Logout from "./Logout";
import ChangePassword from "./ChangePassword";
import { FaWhatsapp } from "react-icons/fa";
import ProtectedRoute from "./ProtectedRoute";
function App() {
  return (
    <>
      <UserAuthContextProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="complain-form" element={<ComplainForm />} />
          <Route path="all-complains" element={
            <ProtectedRoute>
            <ComplainTable />
            </ProtectedRoute>
            } />
          <Route path="change-password" element={<ChangePassword />} />
          <Route path="logout" element={<Logout />} />
          <Route path="login-admin" element={<Login />} />
        </Routes>
      </UserAuthContextProvider>

      {/* WhatsApp Floating Button */}
      {/* <a
        href="https://wa.me/923154951759"
        target="_blank"
        rel="noopener noreferrer"
        className="whatsapp-float"
      >
        <FaWhatsapp size={40} />
      </a> */}

      <ToastContainer />
    </>
  );
}

export default App;
