import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { BiLogOutCircle } from "react-icons/bi";
import { Link } from "react-router-dom";
import init from "./firebase";
import { CSVLink } from "react-csv";
import dayjs from "dayjs";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";

const ComplainTable = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState([]);
  const getComplainList = async () => {
    const mycollection = collection(init.db, "complains");
    let q = query(mycollection, orderBy("createdAt", "desc"));
    const data = await getDocs(q);
    setData(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
  };
  useEffect(() => {
    getComplainList();
  }, []);

  const [img, setImg] = useState(null);
  const deletePackage = async (id) => {
    const choice = window.confirm("Are you sure want to delete?");
    if (choice) {
      try {
        const result = await deleteDoc(doc(init.db, "complains", id));
        toast.success("Complaint deleted Successfully");
        getComplainList();
      } catch (err) {
        console.log(err);
      }
    } else {
      return;
    }
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="card">
          <div className="card-body">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="card " style={{ backgroundColor: "#3b6988" }}>
                    <div className="card-header">
                      <div>
                        <div className="">
                          <div className="col-md-12">
                            <input
                              type="text"
                              placeholder="search complain..."
                              className="form-control"
                              value={searchQuery}
                              onChange={(e) => {
                                setSearchQuery(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="exvbutton">
                          <div className="row">
                            <div
                              className=""
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "10px",
                              }}
                            >
                              <div className="col-md-6">
                                <button className="btn btn-success text-white">
                                  <CSVLink
                                    data={data}
                                    style={{ color: "white" }}
                                    filename={"all-complains"}
                                    target="_blank"
                                  >
                                    Export Data
                                  </CSVLink>
                                </button>
                              </div>
                              <div className="col-md-6">
                                <Link
                                  className="sidebar-link waves-effect waves-dark sidebar-link"
                                  to="/logout"
                                  aria-expanded="false"
                                >
                                  <button className="btn btn-dark mx-2">
                                    {" "}
                                    Logout
                                    <BiLogOutCircle
                                      size={23}
                                      style={{
                                        display: "inline-block",
                                        color: "white",
                                        textAlign: "center",
                                        width: "35px",
                                      }}
                                    />{" "}
                                  </button>
                                </Link>
                              </div>
                            </div>
                            <center>
                              {" "}
                              <div className="col-md-6 mt-2">
                                <Link
                                  className="sidebar-link waves-effect waves-dark sidebar-link"
                                  to="/change-password"
                                  aria-expanded="false"
                                >
                                  <button className="btn btn-dark mx-2">
                                    {" "}
                                    Change Password
                                  </button>
                                </Link>
                              </div>
                            </center>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive ">
                        <table className="table  table-bordered  shadow text-white">
                          <thead>
                            <tr>
                              <th className="text-center">S. No.</th>
                              <th className="text-center">User ID</th>
                              <th className="text-center">User Name</th>
                              <th className="text-center">
                                User Mobile Number
                              </th>
                              <th className="text-center">Dealer number</th>
                              {/* <th className="text-center">Issue Image</th> */}
                              <th className="text-center">Description</th>
                              <th className="text-center">Request Date</th>
                              <th className="text-center">Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data ? (
                              data
                                ?.filter((item) =>
                                  item.name?.includes(searchQuery)
                                )
                                .map((item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>{item.userId}</td>
                                      <td>{item.name}</td>
                                      <td>
                                        {item.mobno}
                                        <a
                                          href={`https://wa.me/${item.mobno}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{
                                            marginLeft: "10px",
                                            color: "green",
                                            fontSize: "20px",
                                            textDecoration: "none",
                                          }}
                                        >
                                          📱
                                        </a>
                                      </td>
                                      <td>{item?.delarMobno}</td>
                                      <td>{item.description}</td>
                                      <td>
                                        {dayjs(
                                          item.createdAt.seconds * 1000
                                        ).format("dddd, D MMMM , YYYY h:mm A")}
                                      </td>
                                      <td>
                                        <button
                                          className="btn btn-sm btn-danger"
                                          onClick={() => deletePackage(item.id)}
                                        >
                                          Delete
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })
                            ) : (
                              <h4>No Complains</h4>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Issue Image</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          {img ? (
            <img
              src={img}
              style={{
                height: "100%",
                width: "100%",
                borderRadius: "10px",
                objectFit: "contain",
              }}
            />
          ) : (
            <center>
              <h5 className="m-4">Not Image Added</h5>
            </center>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ComplainTable;
